import React, { useRef, Suspense } from 'react';
import {
  Typography,
  Box,
  Container,
  Section,
  DetailsLink,
  Figure,
  Image,
  Layout,
  Grid,
  Link,
  Button,
} from '../components';
import expertiseImg from '../images/expertise/header.svg';
import {
  businessItems,
  featuresExpertise,
  techLanguages,
} from '../config/data';
import { useOnScreen, useVisibleContactForm } from '../hooks';
import styled from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';
import Flex from '../components/Flex';

const ContactForm = React.lazy(() => import('../components/ContactForm'));

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 16px;
  flex-direction: column;

  @media (min-width: 600px) {
    flex-direction: row;
  }
`;
const MotionBox = motion(
  styled.div`
    border: 1px solid #152539;
    cursor: pointer;
    background-color: var(--tech-bg);
    height: 130px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  `
);
const techBoxVariants = {
  initial: { opacity: 0, y: 20 },
  hover: { scale: 1.1 },
  enter: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: -20 },
};
const GridContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;
const ExpertisePage = () => {
  const ref = useRef();
  const onScreen = useOnScreen(ref, '-50px');
  const showContactForm = useVisibleContactForm(onScreen);

  return (
    <Layout title="Expertise">
      <Container mt={{ _: 8, md: 12 }}>
        <Section>
          <Grid gridTemplateColumns={{ _: '1fr', sm: '3fr 2fr' }}>
            <Box>
              <Typography variant="heading1" mb={1}>
                Complete Enterprise Solutions
              </Typography>
              <Typography
                variant="heading4"
                color=""
                mb={2}
                mt={{ _: 1, md: 3 }}
              >
                Grow your business with our market-leading experts.
              </Typography>
              <Typography variant="paragraph1" fontWeight="normal" mb={4}>
                Our highly proficient engineers are trained to adapt to any
                business development environment and efficiently fulfill
                business demand. We offer expertise in all mainstream technology
                stacks and produce the output exactly as imagined by our client.
              </Typography>
            </Box>
            <Box display={{ _: 'none', sm: 'block' }}>
              <Figure
                src={expertiseImg}
                alt="Improve your Business now!"
                barColor="#008FD4"
                barPosition="left"
                position="right"
                width="80%"
              />
            </Box>
          </Grid>
        </Section>
        <Section>
          <Grid mt={4} gridTemplateColumns={{ _: '1fr', sm: '1fr 1fr' }}>
            {businessItems.map(business => (
              <Box key={business.label}>
                <DetailsLink
                  to={business.link}
                  variant="underline"
                  showStartArrow={true}
                  disabled={!business.link}
                >
                  {business.label}
                </DetailsLink>
              </Box>
            ))}
          </Grid>
        </Section>
        <Section ref={ref}>
          <Typography variant="heading3" mb={8}>
            Overwhelmed with finding expertise? See our simplified service
            models.
          </Typography>

          <Grid gridTemplateColumns={{ _: '1fr', sm: '1fr 1fr 1fr' }}>
            {featuresExpertise.map(feature => (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
                textAlign="center"
                key={feature.title}
              >
                <Image mb={2} src={feature.image} alt={feature.title} />
                <Typography variant="heading5" my={1}>
                  {feature.title}
                </Typography>
                <Typography variant="body2">{feature.content}</Typography>
                <DetailsLink to={feature.link} mt={2}>
                  {feature.linkLabel}
                </DetailsLink>
              </Box>
            ))}
          </Grid>
        </Section>

        <Section>
          <Grid
            gridTemplateColumns={{ _: '1fr', sm: '1fr' }}
            justifyItems="center"
          >
            <Box>
              <Typography
                style={{ textAlign: 'center' }}
                variant="heading3"
                mb={3}
              >
                Technologies We Have Expertise In
              </Typography>
            </Box>
          </Grid>
          <Flex>
            <AnimatePresence>
              {techLanguages.map((tech, index) => (
                <div key={index}>
                  <MotionBox
                    initial="initial"
                    animate="enter"
                    exit="exit"
                    whileHover="hover"
                    variants={techBoxVariants}
                    transition={{ duration: 0.5 }}
                  >
                    <Image width="100px" src={tech} alt="Technology tool" />
                  </MotionBox>
                </div>
              ))}
            </AnimatePresence>
          </Flex>
        </Section>
        <Section>
          <Grid gridTemplateColumns={{ _: '1fr', sm: '1fr' }}>
            <Box>
              <Typography
                style={{ textAlign: 'center' }}
                variant="heading3"
                mb={3}
              >
                Ready To Build Your Brand?
              </Typography>
            </Box>
          </Grid>
          <GridContainer>
            <ButtonContainer>
              <Link to="/contact/">
                <Button variant="contained" color="primary" primary>
                  Contact Us
                </Button>
              </Link>
              <Link to="/case-studies/">
                <Button variant="contained" color="primary">
                  View All Services
                </Button>
              </Link>
            </ButtonContainer>
          </GridContainer>
        </Section>
        {showContactForm && (
          <Section>
            <Suspense fallback={<div />}>
              <ContactForm />
            </Suspense>
          </Section>
        )}
      </Container>
    </Layout>
  );
};

export default ExpertisePage;
